<template>
  <b-card>
    <b-modal
      v-model="dialog"
      size="lg"
      :title="modalTitle"
      ok-title="Save"
      header-close-content=""
      :ok-disabled="isDisableBtn"
      @ok="save"
      @cancel="close"
    >
      <b-container fluid>
        <b-form></b-form>
        <b-row>
          <!-- name  -->
          <b-col cols="12">
            <b-form-group id="input-name">
              <label for="nameInput">Name:</label>
              <b-form-input
                name="name"
                v-model="editedItem.name"
                type="text"
                aria-describedby="input-name"
                data-vv-as="name"
                :class="{ input: true, 'is-danger': errors.has('name') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('name')" class="is-danger-text position-relative">{{
                errors.first('name')
              }}</span>
            </b-form-group>
          </b-col>
          <!-- handler  -->
          <b-col cols="3">
            <b-form-group id="input-handler">
              <label for="nameInput">Handler:</label>
              <b-form-input
                :disabled="!isCreated"
                name="handler"
                v-model="editedItem.handler"
                type="text"
                aria-describedby="input-handler"
                data-vv-as="handler"
                :class="{ input: true, 'is-danger': errors.has('handler') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('handler')" class="is-danger-text position-relative">{{
                errors.first('handler')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- fee  -->
          <b-col cols="3">
            <b-form-group id="input-fee">
              <label for="priority">Fee:</label>
              <b-form-input
                name="fee"
                :disabled="editedItem.handler !== 'wallet'"
                v-model="editedItem.fee"
                type="number"
                data-vv-as="fee"
                :class="{ input: true, 'is-danger': errors.has('fee') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('fee')" class="is-danger-text position-relative">{{ errors.first('fee') }}</span>
            </b-form-group>
          </b-col>

          <!-- rate  -->
          <b-col cols="3">
            <b-form-group id="input-rate">
              <label for="priority">Rate:</label>
              <b-form-input
                :disabled="editedItem.handler !== 'wallet'"
                name="rate"
                v-model="editedItem.rate"
                type="number"
                data-vv-as="rate"
                :class="{ input: true, 'is-danger': errors.has('rate') }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('rate')" class="is-danger-text position-relative">{{
                errors.first('rate')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Deposit Fee  -->
          <b-col cols="6">
            <b-form-group id="input-deposit_fee">
              <label for="deposit_fee_input">Deposit Fee:</label>
              <b-form-input
                :disabled="editedItem.handler === 'wallet'"
                name="deposit_fee"
                v-model="editedItem.deposit_fee"
                type="number"
                data-vv-as="deposit_fee"
                :class="{
                  input: true,
                  'is-danger': errors.has('deposit_fee')
                }"
                v-validate="{ required: true, min: 0 }"
              ></b-form-input>
              <span v-show="errors.has('deposit_fee')" class="is-danger-text position-relative">{{
                errors.first('deposit_fee')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Deposit Rate  -->
          <b-col cols="6">
            <b-form-group id="input-deposit_rate">
              <label for="deposit_rate_input">Deposit Rate:</label>
              <b-form-input
                :disabled="editedItem.handler === 'wallet'"
                name="deposit_rate"
                type="number"
                v-model="editedItem.deposit_rate"
                data-vv-as="deposit_rate"
                :class="{
                  input: true,
                  'is-danger': errors.has('deposit_rate')
                }"
                v-validate="{ required: true, min: 0, max: 1 }"
              ></b-form-input>
              <span v-show="errors.has('deposit_rate')" class="is-danger-text position-relative">{{
                errors.first('deposit_rate')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Withdraw Fee  -->
          <b-col cols="3">
            <b-form-group id="input-withdraw_fee">
              <label for="priority">Withdraw Fee:</label>
              <b-form-input
                name="withdraw_fee"
                v-model="editedItem.withdraw_fee"
                type="number"
                min="0"
                data-vv-as="withdraw_fee"
                :class="{
                  input: true,
                  'is-danger': errors.has('withdraw_fee')
                }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('withdraw_fee')" class="is-danger-text position-relative">{{
                errors.first('withdraw_fee')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Withdraw Rate  -->
          <b-col cols="3">
            <b-form-group id="input-withdraw_rate">
              <label for="withdraw_rate_input">Withdraw Rate:</label>
              <b-form-input
                id="withdraw_rate_input"
                name="withdraw_rate"
                v-model="editedItem.withdraw_rate"
                type="number"
                data-vv-as="withdraw_rate"
                :class="{
                  input: true,
                  'is-danger': errors.has('withdraw_rate')
                }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('withdraw_rate')" class="is-danger-text position-relative">{{
                errors.first('withdraw_rate')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Type -->
          <b-col cols="3">
            <b-form-group id="input-type">
              <label for="type_input">Type:</label>
              <b-form-input id="type_input" name="type" v-model="editedItem.type" type="number"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- Allow Deposit  -->

          <b-col cols="3">
            <b-form-group id="input-allow_deposit" label="Allow Deposit:" label-for="allow_deposit">
              <b-form-select v-model="editedItem.allow_deposit" :options="affiliateList" name="allow_deposit">
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select allow deposit --</b-form-select-option>
                </template></b-form-select
              >
            </b-form-group>
          </b-col>

          <!-- Allow Withdraw  -->

          <b-col cols="3">
            <b-form-group id="input-allow_withdraw" label="Allow Withdraw:" label-for="allow_withdraw">
              <b-form-select v-model="editedItem.allow_withdraw" :options="affiliateList" name="allow_withdraw">
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select allow withdraw --</b-form-select-option>
                </template></b-form-select
              >
            </b-form-group>
          </b-col>

          <!-- Allow Transaction  -->

          <b-col cols="3">
            <b-form-group id="input-allow_transaction" label="Allow Transaction:" label-for="allow_transaction">
              <b-form-select v-model="editedItem.allow_transaction" :options="affiliateList" name="allow_transaction">
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select allow transaction --</b-form-select-option>
                </template></b-form-select
              >
            </b-form-group>
          </b-col>

          <!-- Deposit Priority  -->
          <b-col cols="3">
            <b-form-group id="input-deposit_priority">
              <label for="deposit_priority_input">Deposit Priority:</label>
              <b-form-input
                id="deposit_priority_input"
                name="deposit_priority"
                v-model="editedItem.deposit_priority"
                type="number"
                data-vv-as="deposit_priority"
                :class="{
                  input: true,
                  'is-danger': errors.has('deposit_priority')
                }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('deposit_priority')" class="is-danger-text position-relative">{{
                errors.first('deposit_priority')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Withdraw Priority  -->
          <b-col cols="3">
            <b-form-group id="input-withdraw_priority">
              <label for="withdraw_priority_input">Withdraw Priority:</label>
              <b-form-input
                id="withdraw_priority_input"
                name="withdraw_priority"
                v-model="editedItem.withdraw_priority"
                type="number"
                data-vv-as="withdraw_priority"
                :class="{
                  input: true,
                  'is-danger': errors.has('withdraw_priority')
                }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('withdraw_priority')" class="is-danger-text position-relative">{{
                errors.first('withdraw_priority')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- Transaction Priority  -->
          <b-col cols="3">
            <b-form-group id="input-transaction_priority">
              <label for="transaction_priority_input">Transaction Priority:</label>
              <b-form-input
                id="transaction_priority_input"
                name="transaction_priority"
                v-model="editedItem.transaction_priority"
                type="number"
                data-vv-as="transaction_priority"
                :class="{
                  input: true,
                  'is-danger': errors.has('transaction_priority')
                }"
                v-validate="{ required: true }"
              ></b-form-input>
              <span v-show="errors.has('transaction_priority')" class="is-danger-text position-relative">{{
                errors.first('transaction_priority')
              }}</span>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-form-group id="input-transaction_priority">
              <label for="metadata_input">Metadata:</label>
              <b-form-input
                id="metadata_input"
                name="metadata"
                v-model="editedItem.metadata"
                type="text"
                data-vv-as="metadata"
                :class="{
                  input: true,
                  'is-danger': errors.has('metadata')
                }"
              ></b-form-input>
              <span v-show="errors.has('metadata')" class="is-danger-text position-relative">{{
                errors.first('metadata')
              }}</span>
            </b-form-group>
          </b-col>

          <!-- image url  -->
          <b-col cols="6">
            <b-form-group id="input-img-url" label="Image URL:" label-for="img-url" aria-describedby="input-image-help">
              <b-form-input id="img-url" v-model="editedItem.image_url" type="text"></b-form-input>
            </b-form-group>
          </b-col>

          <!-- image file  -->
          <b-col cols="6">
            <b-form-group id="input-img-file" label="Image File:" label-for="img-file">
              <b-form-file
                v-model="editedItem.image_file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
              ></b-form-file>
            </b-form-group>
          </b-col>

          <!-- status -->
          <b-col cols="12">
            <b-form-group id="input-status" label="Status:" label-for="status">
              <!-- {{ filter_status }} -->
              <b-form-select
                v-model="filter_status"
                :options="statusList"
                name="status"
                data-vv-as="status"
                :class="{ input: true, 'is-danger': errors.has('status') }"
                v-validate="{
                  required: true,
                  excluded: '--Please select status--'
                }"
              >
                <template v-slot:first>
                  <b-form-select-option :value="null">-- Please select status --</b-form-select-option>
                </template></b-form-select
              >
              <span v-show="errors.has('status')" class="is-danger-text position-relative">{{
                errors.first('status')
              }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
    </b-modal>
    <b-row class="table-filter">
      <b-col cols="12">
        <h4 class="b-card-title">Payment List</h4>
      </b-col>
      <!-- FilterName -->
      <b-col cols="4">
        <b-form-group id="input-name-filter" label="Payments" label-for="name-filter">
          <b-form-input
            id="name-filter"
            v-model="filter_name"
            type="text"
            placeholder="Search payments ..."
            @input="onFilterName"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <!-- Filter status  -->
      <b-col cols="4">
        <b-form-group id="input-status-filter" label="status" label-for="status-filter">
          <b-form-select id="status-filter" v-model="filter_status" :options="statusList" @change="fetchData">
            <template v-slot:first>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template></b-form-select
          >
        </b-form-group>
      </b-col>
      <b-col cols="4">
        <b-button variant="primary" class="float-right" @click="addItem">Add Payments</b-button>
      </b-col>
    </b-row>
    <b-table
      striped
      :fields="headers"
      :items="items"
      :busy="isBusy"
      :current-page="currentPage"
      responsive
      bordered
      class="elevation-1 data-table"
    >
      <template v-slot:cell(fee)="item">
        {{ formatPrice(item.item.fee) }}
      </template>
      <template v-slot:cell(image_preview)="item">
        <img :src="item.value[0].src" style="height: 50px" @click="openGallery(item.item.id)" />
        <LightBox
          :ref="'lightbox_' + item.item.id"
          :media="item.value"
          :show-light-box="false"
          :show-thumbs="false"
        ></LightBox>
      </template>

      <template v-slot:cell(status)="item">
        <b-badge class="mr-1" v-if="item.value === 1" variant="success">Active</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0 && item.item.deleted_at" variant="danger">Deleted</b-badge>
        <b-badge class="mr-1" v-else-if="item.value === 0" variant="warning">Inactive</b-badge>
      </template>

      <template v-slot:cell(actions)="item">
        <v-icon class="text-success mr-3" @click="editItem(item.item)">mdi-pencil</v-icon>
        <v-icon class="text-danger" @click="deleteItem(item.item)">mdi-delete</v-icon>
      </template>
    </b-table>
    <b-row>
      <b-col cols="5">
        Showing {{ (currentPage - 1) * perPage + 1 }}-{{
          currentPage * perPage > rows ? rows : currentPage * perPage
        }}
        of {{ rows }}.
      </b-col>
      <b-col style="display: flex; justify-content: flex-end">
        <b-form-group
          id="input-per-page"
          label="Rows per page: "
          label-for="per-page"
          label-cols="8"
          style="margin-right: 20px; margin-bottom: 0"
        >
          <b-form-select
            id="per-page"
            v-model="perPage"
            size="sm"
            :options="[5, 10, 15, 20]"
            style="width: 65px"
            @change="fetchData"
          ></b-form-select>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          align="right"
          v-on:input="fetchData"
        ></b-pagination>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import ApiService from '@/common/api.service.js';
import LightBox from 'vue-image-lightbox';
import _ from 'lodash';
import axios from 'axios';
import { CurrentTime } from '@/utils/time.js';
export default {
  name: 'Payment List',
  components: {
    LightBox
  },
  data() {
    return {
      isCreated: true,
      search: '',
      isBusy: false,
      filter_name: '',
      filter_screen: null,
      filter_status: null,
      statusList: [
        { value: 1, text: 'Active' },
        { value: 0, text: 'Inactive' }
      ],
      headers: [
        { text: 'Id', key: 'id' },
        { text: 'Name', key: 'name' },
        { text: 'Handler', key: 'handler' },
        { text: 'Image URL', key: 'image_preview' },
        { text: 'Fee', key: 'fee' },
        { text: 'Rate', key: 'rate' },
        { text: 'Deposit Fee', key: 'deposit_fee' },
        { text: 'Deposit Rate', key: 'deposit_rate' },
        { text: 'Withdraw_Fee', key: 'withdraw_fee' },
        { text: 'Withdraw_Rate', key: 'withdraw_rate' },
        { text: 'Type', key: 'type' },
        { text: 'Allow_Deposit', key: 'allow_deposit' },
        { text: 'Allow_Withdraw', key: 'allow_withdraw' },
        { text: 'Allow_Transaction', key: 'allow_transaction' },
        { text: 'Deposit_Priority', key: 'deposit_priority' },
        { text: 'Withdraw_Priority', key: 'withdraw_priority' },
        { text: 'Transaction_Priority', key: 'transaction_priority' },
        { text: 'Metadata', key: 'metadata' },
        { text: 'status', key: 'status', class: 'text-center' },
        { text: 'Actions', key: 'actions', class: 'text-center' }
      ],
      // Modal data
      items: [],
      screenPosList: [],
      screenAdsList: [],
      posList: [],
      affiliateList: [
        { value: 1, text: 'Yes' },
        { value: 0, text: 'No' }
      ],
      selectedPayments: null,
      selectedPos: null,
      modalTitle: '',
      // loading: false,
      rows: 0,
      perPage: 10,
      currentPage: 1,
      editedItem: {
        id: '',
        name: '',
        handler: '',
        image_url: '',
        fee: '',
        rate: '',
        withdraw_fee: '',
        withdraw_rate: '',
        deposit_fee: '',
        deposit_rate: '',
        status: 0,
        type: '',
        allow_deposit: '',
        allow_withdraw: '',
        allow_transaction: '',
        deposit_priority: '',
        withdraw_priority: '',
        transaction_priority: '',
        image_file: null,
        metadata: '',
        deleted_at: ''
      },
      defaultItem: {
        id: '',
        name: '',
        handler: '',
        image_url: '',
        fee: '',
        rate: '',
        withdraw_fee: '',
        withdraw_rate: '',
        deposit_fee: '',
        deposit_rate: '',
        status: 0,
        type: '',
        metadata: '',
        allow_deposit: '',
        allow_withdraw: '',
        allow_transaction: '',
        deposit_priority: '',
        withdraw_priority: '',
        transaction_priority: '',
        image_file: null,
        deleted_at: ''
      },
      dialog: false,
      editedIndex: -1,
      dpOptions: {
        format: 'HH:mm DD/MM/YYYY',
        sideBySide: true
      }
    };
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    editedItem: function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.editedItem = newVal;
      }
    }
  },
  computed: {
    isDisableBtn() {
      return (
        this.errors.has('name') ||
        !this.editedItem.name ||
        this.errors.has('fee') ||
        this.errors.has('rate') ||
        this.errors.has('deposit_fee') ||
        this.errors.has('deposit_rate') ||
        this.errors.has('withdraw_fee') ||
        this.errors.has('withdraw_rate') ||
        this.errors.has('deposit_priority') ||
        this.errors.has('withdraw_priority') ||
        this.errors.has('transaction_priority')
      );
    }
  },
  created() {
    this.fetchData();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: 'Payments',
        route: 'payments'
      },
      { title: 'Payments' }
    ]);
  },
  methods: {
    onFilterName: _.debounce(function () {
      this.fetchData();
    }, 500),
    openGallery(id) {
      this.$refs[`lightbox_${id}`].showImage(0);
    },
    fetchData() {
      // this.loading = true;
      this.isBusy = true;
      let limit = this.perPage;
      let offset = this.currentPage;
      ApiService.setHeader();
      const adsPayments = ApiService.get(
        'payments',
        `?name=${this.filter_name}&status=${
          this.filter_status == null ? '' : this.filter_status
        }&offset=${offset}&limit=${limit}`
      );
      axios
        .all([adsPayments])
        .then(
          axios.spread((...response) => {
            const adsPayments = response[0];
            this.items = adsPayments.data.data.paymentList.map((ads) => {
              return {
                ...ads,
                image_preview: [{ thumb: ads.image_url, src: ads.image_url }]
              };
            });
            this.rows = adsPayments.data.data.total;
            this.isBusy = false;
          })
        )
        .catch((errors) => {
          this.errors.push(errors);
          this.isBusy = false;
        });
    },
    addItem: function () {
      this.dialog = true;
      this.isCreated = true;
      this.modalTitle = 'Add Payments';
    },
    editItem: async function (item) {
      this.isCreated = false;
      this.dialog = true;
      this.modalTitle = 'Update Payments';
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = {
        ...item
      };
      this.filter_status = item.status;
      this.selectedPayments = item.id;
    },
    deleteItem(item) {
      const index = this.items.indexOf(item);
      if (confirm('Are you sure you want to delete this item?')) {
        // var deleted_at = CurrentTime();
        ApiService.delete(`payments/${item.id}`)
          .then((response) => {
            if (response.status === 204) {
              this.editedIndex = this.items.indexOf(item);
              this.editedItem = {
                ...item
              };
              this.editedItem.deleted_at = new Date();
              Object.assign(this.items[this.editedIndex], this.editedItem);
              this.$bvToast.toast('Payments deleted!', {
                title: null,
                variant: 'success',
                autoHideDelay: 3000,
                noCloseButton: true
              });
              this.items[index].status = 0;
            }
          })
          .catch((errors) => {
            this.errors.push(errors);
          });
      }
    },
    close() {
      this.dialog = false;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.selectedPayments = null;
      this.selectedPos = null;
    },
    save(bvModalEvt) {
      // Cancel auto close
      bvModalEvt.preventDefault();
      let allValidated = true;
      if (allValidated) {
        // Get new brand/cate/vendor name for data-table
        // this.editedItem.category_id = this.selectedCategory;
        this.editedItem.status = this.filter_status;
        let formData = new FormData();
        for (const key in this.editedItem) {
          if (this.editedItem.hasOwnProperty(key)) {
            const element = this.editedItem[key];
            if (element !== '' && element !== null) {
              formData.append(key, element);
              // if(key==="rate"){
              //   formData.append("rate", element)
              // }
            }
          }
        }
        if (this.editedItem.image_url === '') {
          formData.append('image_url', '');
        }
        if (this.editedIndex > -1) {
          // Update category
          formData.append('updated_at', CurrentTime());
          axios({
            method: 'PUT',
            url: `payments/${this.items[this.editedIndex].id}`,
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then((response) => {
              if (response.status === 204) {
                this.editedItem.image_preview = [
                  {
                    thumb: this.editedItem.image_url,
                    src: this.editedItem.image_url
                  }
                ];
                Object.assign(this.items[this.editedIndex], this.editedItem);
                this.close();
                this.$bvToast.toast(response.data.data.message, {
                  title: null,
                  variant: 'success',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              } else {
                if (this.errors && this.errors.push) {
                  this.errors.push(response.data.data);
                }
              }
            })
            .catch((error) => {
              if (this.errors && this.errors.push) {
                this.errors.push(error);
              }
            });
        } else {
          // Add Category
          formData.status = 1;
          axios({
            method: 'POST',
            url: 'payments',
            data: formData,
            headers: { 'Content-Type': 'multipart/form-data' }
          })
            .then((response) => {
              if (response.data.status) {
                this.items.unshift({
                  ...response.data.data,
                  image_preview: [
                    {
                      thumb: response.data.data.image_url,
                      src: response.data.data.image_url
                    }
                  ]
                });
                this.close();
                this.$bvToast.toast('Payments added successfully', {
                  title: null,
                  variant: 'success',
                  autoHideDelay: 3000,
                  noCloseButton: true
                });
              } else {
                this.errors.push(response.data.data);
              }
            })
            .catch((error) => {
              if (this.errors && this.errors.push) {
                this.errors.push(error);
              }
            });
        }
      }
    }
  }
};
</script>
